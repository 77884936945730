import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const quodLogoShadow = require("../images/quod-logo-shadow.png")
const heroImage = require("../images/quod-ipad.jpg")
const emmaT = require("../images/emmat.png")

function IndexPage() {
  return (
    <Layout>
      <SEO keywords={[`quiz`, `e-learning`, `training`]} title="Home" />
      <div className="relative bg-white overflow-hidden">
        <div className="max-w-screen-xl mx-auto ">
          <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
            <div className="pt-10 mx-auto max-w-screen-xl px-4 sm:pt-12 sm:px-6 md:pt-16 lg:pt-20 lg:px-8 xl:pt-28">
              <img
                className="block mx-auto lg:mx-32 md:mb-10  mb-6 w-28 md:w-32 lg:w-36"
                src={quodLogoShadow}
                alt="Quod logo"
              />
              <div className="sm:text-center lg:text-left">
                <h2 className="text-4xl tracking-tight leading-10 font-extrabold  text-quod-purple-600  sm:text-5xl sm:leading-none md:text-6xl">
                  Beautiful quiz content <br className="xl:hidden" />{" "}
                  <span className="text-quod-green-500">in minutes</span>
                </h2>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                  Reinforce training and critical job learning, engage remote
                  learners, and consolidate key messages to improve proficiency
                  performance.
                </p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                  <div className="rounded-md shadow">
                    <a
                      href="/pricing"
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-quod-green-600 hover:bg-quod-green-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                    >
                      Get started
                    </a>
                  </div>
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <a
                      href="/features"
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-quod-green-700 bg-quod-green-100 hover:text-quod-green-600 hover:bg-quod-green-50 focus:outline-none focus:shadow-outline focus:border-quod-green-300 transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                    >
                      Learn more
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <svg
              className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <img
            className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
            src={heroImage}
            style={{}}
            alt=""
          />
        </div>
      </div>

      <div className="py-6 my-6 lg:my-16 bg-whitey">
        <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center">
            <p className="text-base leading-6 text-quod-green-600 font-semibold tracking-wide uppercase">
              Quizzes On Demand
            </p>
            <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight  text-quod-purple-600 sm:text-4xl sm:leading-10">
              A better way to test knowledge
            </h3>
            <p className="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
              Identify gaps in knowledge, reinforce learning, improve retention,
              and increase performance all with one tool.
            </p>
          </div>
          <div className="mt-10">
            <ul className="md:grid md:grid-cols-2 md:col-gap-8 md:row-gap-10">
              <li>
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-quod-green-500 text-white">
                      <svg
                        className="h-6 w-6"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="ml-4">
                    <h5 className="text-lg leading-6 font-medium  text-quod-purple-600 ">
                      Simple editing; powerful quizzes
                    </h5>
                    <p className="mt-2 text-base leading-6 text-gray-500">
                      Quod’s uncomplicated authoring system means that you can
                      create effective and engaging quizzes in a matter of
                      minutes, not hours!
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-10 md:mt-0">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-quod-green-500 text-white">
                      <svg
                        className="h-6 w-6"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="ml-4">
                    <h5 className="text-lg leading-6 font-medium  text-quod-purple-600 ">
                      No hidden fees
                    </h5>
                    <p className="mt-2 text-base leading-6 text-gray-500">
                      Two straightforward plans to suit all requirements, with a
                      30 day free trial.
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-10 md:mt-0">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-quod-green-500 text-white">
                      <svg
                        className="h-6 w-6"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M13 10V3L4 14h7v7l9-11h-7z"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="ml-4">
                    <h5 className="text-lg leading-6 font-medium  text-quod-purple-600 ">
                      Instant publishing
                    </h5>
                    <p className="mt-2 text-base leading-6 text-gray-500">
                      Export your quizzes as SCORM files at the click of a
                      button - ready to use instantly.
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-10 md:mt-0">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-quod-green-500 text-white">
                      <svg
                        className="h-6 w-6"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="ml-4">
                    <h5 className="text-lg leading-6 font-medium  text-quod-purple-600 ">
                      Responsive mobile output
                    </h5>
                    <p className="mt-2 text-base leading-6 text-gray-500">
                      Multiple devices, no problem! Quod has been designed with
                      compatibility in mind.
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="max-w-screen-xl mx-auto px-4 py-6 sm:px-6 lg:py-16 lg:px-8">
        <div class="px-6 py-6 bg-quod-purple-600 rounded-lg md:py-12 md:px-12 lg:py-16 lg:px-16 ">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10">
              Trusted by training teams all over the world
            </h2>
            <p className="mt-3 text-xl leading-7 text-indigo-200 sm:mt-4">
              Already embedded into training organizations throughout the world,
              now you can experience the power of Quod.
            </p>
          </div>
          <div className="mt-10 text-center sm:max-w-3xl sm:mx-auto sm:grid sm:grid-cols-3 sm:gap-8">
            <div className="mt-10 sm:mt-0">
              <p className="text-5xl leading-none font-extrabold text-white">
                100k+
              </p>
              <p className="mt-2 text-lg leading-6 font-medium text-indigo-200">
                Questions asked
              </p>
            </div>
            <div className="mt-10 sm:mt-0">
              <p className="text-5xl leading-none font-extrabold text-white">
                &infin;
              </p>
              <p className="mt-2 text-lg leading-6 font-medium text-indigo-200">
                Unlimited quizzes
              </p>
            </div>
            <div className="mt-10 sm:mt-0">
              <p className="text-5xl leading-none font-extrabold text-white">
                24/7
              </p>
              <p className="mt-2 text-lg leading-6 font-medium text-indigo-200">
                Availability
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white mb-12 lg:mb-16 mt-3 lg:mt-12">
        <div className="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-24 lg:px-8">
          <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-quod-purple-600 sm:text-4xl sm:leading-10">
            Ready to dive in?
            <br />
            <span className={""}>
              Start your free trial today. <br />
            </span>
          </h2>
          <p className={"pt-4"}>
            30 day risk-free trial - create your first quiz today!
          </p>
          <div className="mt-8 flex justify-center">
            <div className="inline-flex rounded-md shadow">
              <a
                href="/pricing"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-quod-green-600 hover:bg-quod-green-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
              >
                Get started
              </a>
            </div>
            <div className="ml-3 inline-flex">
              <a
                href="/features"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-quod-green-700 bg-quod-green-100 hover:text-quod-green-600 hover:bg-quod-green-50  focus:outline-none focus:shadow-outline focus:border-indigo-300 transition duration-150 ease-in-out"
              >
                Learn more
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="relative bg-quod-green-800 ">
        <div className="h-56 bg-quod-purple-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
          <img
            className="w-full h-full object-cover"
            src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&blend=2EB4A5&sat=-100&blend-mode=multiply"
            alt="Support team"
          />
        </div>
        <div className="relative max-w-screen-xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
          <div className="md:ml-auto md:w-1/2 md:pl-10">
            <div className="text-base leading-6 font-semibold uppercase tracking-wider text-gray-300">
              Award-winning support
            </div>
            <h2 className="mt-2 text-white text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">
              We’re here to help
            </h2>
            <p className="mt-3 text-lg leading-7 text-gray-300">
              From our comprehensive online help center to chat, email and phone
              support - we’re here for you!
            </p>
            <div className="mt-8">
              <div className="inline-flex rounded-md shadow">
                <a
                  href="/help"
                  className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray-900 bg-white hover:text-gray-600 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                >
                  Visit the help center
                  <svg
                    className="-mr-1 ml-3 h-5 w-5 text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5zM5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"
                      clipRule="evenodd"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
